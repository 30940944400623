const successMessages = {
  // SUCCESS MESSAGES - CREATE
  customizable_column_created_success: {
    pt: 'Coluna personalizável criado com sucesso',
    en: 'Customizable column successfully created',
    es: 'Columna personalizable creado con éxito',
  },
  create_measurer_type_success: {
    pt: 'Tipo de medição cadastrado com sucesso',
    en: 'Measurer type created successfully',
    es: 'Tipo de medición registrado con éxito',
  },
  column_created_success: {
    pt: 'Campo criado com sucesso',
    en: 'Field successfully created',
    es: 'Campo creado con éxito',
  },
  create_report_success: {
    pt: 'Relatório cadastrado com sucesso',
    en: 'Report created successfully',
    es: 'Informe creado con éxito',
  },
  status_group_created_success: {
    pt: 'Grupo de status criado com sucesso',
    en: 'Group of status successfully created',
    es: 'Grupo de status creado con éxito',
  },
  service_created_success: {
    pt: 'Serviço criado com sucesso',
    en: 'Service successfully created',
    es: 'Servicio creado con éxito',
  },
  user_created_success: {
    pt: 'Usuário criado com sucesso',
    en: 'User successfully created',
    es: 'Usuario creado con éxito',
  },
  form_of_service_created_success: {
    pt: 'Forma de atendimento criado com sucesso',
    en: 'Type of attention successfully create',
    es: 'Forma de servicio creado con éxito',
  },
  customizable_field_created_success: {
    pt: 'Campo personalizável criado com sucesso',
    en: 'Customizable field successfully created',
    es: 'Campo personalizable creado con éxito',
  },
  object_type_created_success: {
    pt: 'Tipo de objeto criado com sucesso',
    en: 'Type of object successfully created',
    es: 'Tipo de objeto creado con éxito',
  },
  record_created_success: {
    pt: 'Registro criado com sucesso',
    en: 'Record created with success',
    es: 'Registro creado con éxito',
  },
  create_project_success: {
    pt: 'Projeto cadastrado com sucesso',
    en: 'Project created successfully',
    es: 'Proyecto registrado con éxito',
  },
  register_create_success: {
    pt: 'Registro cadastrado com sucesso',
    en: 'Record created successfully',
    es: 'Registro cadastrado con éxito',
  },
  create_company_success: {
    pt: 'Empresa cadastrada com sucesso',
    en: 'Company created successfully',
    es: 'Compañía registrada con éxito',
  },
  create_enterprise_success: {
    pt: 'Empreendimento cadastrado com sucesso',
    en: 'Enterprise created successfully',
    es: 'Emprendimiento creado con éxito',
  },
  designer_created_success: {
    pt: 'Projetista criado com sucesso',
    en: 'Designer successfully created',
    es: 'Diseñador creado con éxito',
  },
  upload_success: {
    pt: 'Arquivos enviados com sucesso',
    en: 'Files uploaded successfully',
    es: 'Archivos subidos con éxito',
  },
  link_enterprise_success: {
    pt: 'Empreendimento vinculado com sucesso',
    en: 'Enterprise linked successfully',
    es: 'Emprendimiento vinculada con éxito',
  },
  success_comment_approve_message: {
    pt: 'Comentário aprovado com sucesso',
    en: 'Comment passed successfully',
    es: 'comentario superado con éxito',
  },
  success_analysis_approve_message: {
    pt: 'Análise aprovada com sucesso',
    en: 'Analysis passed successfully',
    es: 'Análisis superado con éxito',
  },
  service_completed_successfully: {
    pt: 'Serviço preenchido com sucesso',
    en: 'Service completed successfully',
    es: 'Servicio completado con éxito',
  },
  save_service_indicators_success: {
    pt: 'Indicadores do serviço salvos com sucesso',
    en: 'Service indicators saved successfully',
    es: 'Los indicadores de servicio se guardaron correctamente',
  },
  analysis_completed_successfully: {
    pt: 'Análises encaminhada para aprovação',
    en: 'Reviews submitted for approval',
    es: 'Reseñas enviadas para aprobación',
  },
  delete_all_files_successfully: {
    pt: 'Arquivos excluidos com sucesso',
    en: 'Successfully deleted files',
    es: 'Archivos eliminados exitosamente',
  },
  uploading_term_of_use_successfully: {
    pt: 'Upload do termo de uso com sucesos',
    en: 'Uploading the term of use successfully',
    es: 'Subiendo el término de uso exitosamente',
  },

  // SUCCESS MESSAGES - EDIT
  customizable_column_edited_success: {
    pt: 'Coluna personalizável editado com sucesso',
    en: 'Customizable column successfully edited',
    es: 'Columna personalizable editado con éxito',
  },
  edit_measurer_type_success: {
    pt: 'Tipo de medição editado com sucesso',
    en: 'Measurer type edited successfully',
    es: 'Tipo de medición editado con éxito',
  },
  user_edited_success: {
    pt: 'Usuário editado com sucesso',
    en: 'User successfully edited',
    es: 'Usuario editado con éxito',
  },
  column_edited_success: {
    pt: 'Campo editado com sucesso',
    en: 'Field successfully editted',
    es: 'Campo editado con éxito',
  },
  edit_report_success: {
    pt: 'Relatório editado com sucesso',
    en: 'Report edited successfully',
    es: 'Informe editado con éxito',
  },
  status_group_edited_success: {
    pt: 'Grupo de status editado com sucesso',
    en: 'Group of status successfully edited',
    es: 'Grupo de status editado con éxito',
  },
  edit_company_success: {
    pt: 'Empresa editada com sucesso',
    en: 'Company edited successfully',
    es: 'Compañía editada con éxito',
  },
  form_of_service_edited_success: {
    pt: 'Forma de atendimento editado com sucesso',
    en: 'Type of attention successfully edited',
    es: 'Forma de servicio editado con éxito',
  },
  customizable_field_edited_success: {
    pt: 'Campo personalizável editado com sucesso',
    en: 'Customizable field successfully edited',
    es: 'Campo personalizable editado con éxito',
  },
  object_type_edited_success: {
    pt: 'Tipo de objeto editado com sucesso',
    en: 'Type of object successfully edited',
    es: 'Tipo de objeto editado con éxito',
  },
  edit_profile_success: {
    pt: 'Perfil editado com sucesso',
    en: 'Profile edited successfully',
    es: 'Perfil editado con éxito',
  },
  edit_project_success: {
    pt: 'Projeto editado com sucesso',
    en: 'Project edited successfully',
    es: 'Proyecto editado con éxito',
  },
  register_edit_success: {
    pt: 'Registro editado com sucesso',
    en: 'Record edited successfully',
    es: 'Registro editado con éxito',
  },
  record_edited_success: {
    pt: 'Registro editado com sucesso',
    en: 'Record edited with success',
    es: 'Registro editado con éxito',
  },
  edit_enterprise_success: {
    pt: 'Empreendimento editado com sucesso',
    en: 'Enterprise edited successfully',
    es: 'Emprendimiento editado con éxito',
  },
  designer_edited_success: {
    pt: 'Projetista editado com sucesso',
    en: 'Designer successfully edited',
    es: 'Diseñador editado con éxito',
  },
  service_edited_success: {
    pt: 'Serviço editado com sucesso',
    en: 'Service successfully editted',
    es: 'Servicio editado con éxito',
  },
  create_comment_success: {
    pt: 'Comentário criado com sucesso',
    en: 'Comment created successfully',
    es: 'Comentario creado exitosamente',
  },
  update_comment_success: {
    pt: 'Comentário atualizado com sucesso',
    en: 'Comment updated successfully',
    es: 'Comentario atualizado con éxito',
  },
  column_update_success: {
    pt: 'Colunas atualizadas com sucesso',
    en: 'Columns updated successfully',
    es: 'Columnas atualizadas con éxito',
  },
  alter_password_success: {
    pt: 'Senha alterada com sucesso',
    en: 'Profile edited successfully',
    es: 'Contraseña alterada con éxito',
  },

  // SUCCESS MESSAGES - DELETE
  report_deleted_success: {
    pt: 'Relatório deletado com sucesso',
    en: 'Report successfully deleted',
    es: 'Informe eliminado con éxito',
  },
  delete_status_success: {
    pt: 'Grupo de status removido com sucesso',
    en: 'Group of status successfully deleted',
    es: 'Grupo de status eliminado con éxito',
  },
  deleted_success: {
    pt: 'Removido com sucesso',
    en: 'successfully deleted',
    es: 'Eliminado con éxito',
  },
  designer_deleted_success: {
    pt: 'Projetista removido com sucesso',
    en: 'Designer successfully deleted',
    es: 'Diseñador eliminado con éxito',
  },
  delete_service_success: {
    pt: 'Serviço removido com sucesso',
    en: 'Service successfully deleted',
    es: 'Servicio eliminado con éxito',
  },
  delete_success: {
    pt: 'Arquivo deletado com sucesso',
    en: 'Files deleted successfully',
    es: 'Archivo eliminado con éxito',
  },
  project_deleted_success: {
    pt: 'Projeto deletado com sucesso',
    en: 'Project successfully deleted',
    es: 'Proyecto eliminado con éxito',
  },
  register_delete_success: {
    pt: 'Registro deletado com sucesso',
    en: 'Record deleted successfully',
    es: 'Registro eliminado con éxito',
  },
  indicators_select_sucess: {
    pt: 'Indicadores adicionados com sucesso',
    en: 'Successfully increased indicators',
    es: 'Registro eliminado con éxito',
  },
  company_deleted_success: {
    pt: 'Empresa removida com sucesso',
    en: 'Indicators added successfully',
    es: 'Indicadores agregados exitosamente',
  },
  success_analysis_exclude_message: {
    pt: 'Análise excluída com sucesso',
    en: 'Analysis deleted successfully',
    es: 'Análisis eliminado con éxito',
  },
  user_deleted_success: {
    pt: 'Usuário removido com sucesso',
    en: 'User successfully deleted',
    es: 'Usuario eliminado con éxito',
  },
  auto_save_success: {
    pt: 'Serviço salvo automaticamente com sucesso',
    en: 'Service saved automatically',
    es: 'Servicio guardado automáticamente',
  },
  service_save_success: {
    pt: 'Serviço salvo  com sucesso',
    en: 'Service saved successfully',
    es: 'Servicio guardado exitosamente',
  },
  messages_deleted_sucess: {
    pt: 'Mensagens excluidas com sucesso.',
    en: 'Messages deleted successfully.',
    es: 'Mensajes eliminados correctamente.',
  },
  notification_create_sucess: {
    pt: 'Notificação criada com sucesso.',
    en: 'Notification created successfully.',
    es: 'Notificación creada exitosamente.',
  },
};

export default successMessages;
